<template>
    <div class="flex  flex-wrap ">
        <div class="form-check flex pt-2 mr-4" v-for="(option, idx) in getOptions" :key="idx">
            <input class="form-check-input appearance-none rounded-full border-solid h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" :name="fieldData.field_id" v-model="getValue" :value="option" type="radio" :id="option+'radio'+idx" />
            <!-- :value="option" v-model="getValue"  -->
            <label class="form-check-label inline-block text-gray-800 cursor-pointer capitalize" :for="option+'radio'+idx"> {{option}} </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
    },
    computed: {
        getOptions() {
            return this.fieldData.options || [];
        },
        getName() {
            return this.fieldData.field_id;
        },
        getValue: {
            get() {
                return this.fieldData.value || []
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            }
        },
    },
};
</script>

<style>
.checked\:bg-blue-600:checked {
    --bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--bg-opacity));
}
.form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%222%22 fill=%22%23fff%22/%3E%3C/svg%3E");
}
.checked\:border-blue-600:checked {
    --border-opacity: 1;
    border-color: rgb(37 99 235 / var(--border-opacity));
}
.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}
.form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}
</style>